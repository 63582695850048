import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// components
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import FaqAccordion from "../../../components/faq/faq-accordion";

//components
import MktoForm from "../../../components/mkto-form/mkto-form";

//helper functions
import showMktoForm from "../../../helpers/showMktoForm";

const QuickenImage = ({ marginTop, marginBottom }) => {
  return (
    <StaticImage
      src="../../../images/quicken-phone.png"
      alt="Kraken mobile theme"
      placeholder="blurred"
      quality="100"
      style={{ marginTop, marginBottom }}
    />
  );
};

const QuickenCheckMarks = ({ dataList }) => {
  // Show as 2 columns on medium and large devices
  return (
    <div className="row">
      {dataList.map(({ rowId, checkMarks }) => (
        <ul className={`list-unstyled col-md-6 mb-md-4  ${rowId === 1 ? "mb-0" : "mb-4"}`} key={rowId}>
          {checkMarks.map(({ id, text }) => (
            <li className="checkmark d-flex" key={id}>
              <div>
                <FontAwesomeIcon className="mr-2" icon={["fas", "check-circle"]} />
              </div>
              <span dangerouslySetInnerHTML={{ __html: text }}></span>
            </li>
          ))}
        </ul>
      ))}
    </div>
  );
};

const QuickenTextColumn = ({ checkMarks }) => {
  return (
    <div className="row">
      {/* Show image besides checkmarks only on medium devices */}
      <div className="col-md-8 col-lg-12">
        <QuickenCheckMarks dataList={checkMarks} />
      </div>
      <div className="col-md-4 d-none d-md-flex d-lg-none">
        <QuickenImage marginTop="-55px" marginBottom="-70px" />
      </div>
    </div>
  );
};

const QuickenMobileSection = ({ checkMarks = [] }) => {
  return (
    <div className="bg-success text-white mb-4 mb-md-5">
      <div className="container">
        {/* Breakpoint LG, show text column and QuickenImage for large */}
        <div className="row">
          <div className="col-lg-8 pt-4">
            <h4 className="font-weight-semi-bold pt-md-2">
              Now, WaFd Bank offers Quicken Connect, allowing you to access your WaFd Bank transactions from within
              Quicken.
            </h4>
            <QuickenTextColumn checkMarks={checkMarks} />
          </div>
          <div className="col-lg-4 d-none d-lg-flex">
            <QuickenImage marginTop="-70px" marginBottom="-130px" />
          </div>
        </div>
      </div>
    </div>
  );
};

const QuickenDirectConnect = () => {
  const title = "How to Connect Quicken to Your Bank Account",
    description =
      "With Quicken Direct Connect, learn how to connect Quicken to your WaFd banking account allowing you to access your WaFd Bank transactions from within Quicken.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/online-banking/quicken"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-online-banking-03-250.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/online-banking",
      title: "Mobile & Online Banking"
    },
    {
      id: 3,
      active: true,
      title: "Quicken"
    }
  ];

  const checkMarksData = [
    {
      rowId: 1,
      checkMarks: [
        {
          id: 1,
          text: "View balances, enter and edit transaction, and capture receipts on mobile and web."
        },
        {
          id: 2,
          text: "Sign up for email and text alerts to notify you of low balances, unusual spending, and more."
        }
      ]
    },
    {
      rowId: 2,
      checkMarks: [
        {
          id: 1,
          text: "No need to manually enter transactions, saving time and money."
        },
        {
          id: 2,
          text: "Forecast your cash flow."
        },
        {
          id: 2,
          text: "Create and follow a budget."
        }
      ]
    }
  ];

  const quickenInstructions = [
    {
      text: (
        <>
          Please fill out the following{" "}
          <a
            id="quicken-access-form"
            type="button"
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              showMktoForm(1266);
            }}
          >
            Quicken Access Form
          </a>
          .
        </>
      ),
      isReactElement: true
    },
    {
      text: "Upon receipt of your request, it will take 2-3 business days for us to set up your access. A WaFd Banker will send you an email confirming your account has been set up."
    },
    {
      text: "Once you receive your email confirmation, it's time to finish connecting your WaFd account to Quicken."
    },
    {
      text: "Open Quicken and go through the process for adding a new account."
    },
    {
      text: "Enter a friendly name to the account, something like &ldquo;WaFd Bank &ndash; Checking.&rdquo;"
    },
    {
      text: "Under Search for your financial institution, Select &ldquo;WaFd Bank &ndash; Treasury.&rdquo;"
    },
    {
      text: "Enter the User ID and password for your account."
    },
    {
      text: "Now enter the Company ID provided to you in the email from WaFd Bank and hit Next (don't worry if this is not for a company)."
    },
    {
      text: "Select which accounts you want to sync to Quicken and hit Next."
    },
    {
      text: '<span class="text-green-60 font-weight-semi-bold">Congrats! Select Finish.</span>'
    }
  ];

  const faqAccordionData = {
    id: "quicken-faq-accordion",
    title: "Top 3 Quicken Questions",
    faqCategoryNames: ["Quicken"]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <MktoForm />
      <section className="container pb-3">
        <h1 className="mb-2">
          Connect Your Quicken<sup>&reg;</sup> Account
        </h1>
        <div className="row">
          <h3 className="text-green-60 col-lg-8">
            Quickly link your Quicken financial management tool to your WaFd banking account
          </h3>
        </div>
      </section>
      <QuickenMobileSection checkMarks={checkMarksData} />
      <section className="container py-0">
        <div className="row align-items-end">
          <div className="col-lg-8">
            <h4 className="font-weight-semi-bold text-green-60">
              To setup Quicken, please follow the instructions below:
            </h4>
            <ol className="pl-4 mb-0">
              {quickenInstructions.map((step, index) => {
                return step.isReactElement ? (
                  <li>{step.text}</li>
                ) : (
                  <li dangerouslySetInnerHTML={{ __html: step.text }} key={index} />
                );
              })}
            </ol>
          </div>
          <div className="col-lg-4 mt-4 mt-lg-0">
            <div className="d-flex p-2 mb-3 bg-blue-10 border-left-3 border-primary">
              <div>
                <FontAwesomeIcon className="text-primary mr-2" icon={["fas", "info-circle"]} />
              </div>
              <span>
                If you are experiencing issues with Quicken&mdash;for example, you're receiving error codes, or you are
                unable to download transactions&mdash;we recommend that you deactivate and reactivate your accounts.
                This step typically resolves most issues when attempting to synchronize your accounts. It will also be
                helpful to have your online banking open as a cross-reference while you perform this step.
              </span>
            </div>
          </div>
        </div>
      </section>

      <FaqAccordion {...faqAccordionData} />
    </SecondaryLanding>
  );
};

export default QuickenDirectConnect;
